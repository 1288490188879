body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.alertCss {
  width: 300px;
  margin: 0px auto;
  /* background: rgb(255, 231, 231); */
  padding: 25px;
  /* box-shadow: red 1px 1px 14px 0px; */
  transition: 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) all;
  left: 0;
  position: absolute;
  top: -20%;
  right: 0;
}

.top30 {
  top: 30%;
}
