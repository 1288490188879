/* .right-backdrop {
  background: white;
  height: 100%;
  width: 140px;
  position: absolute;
  top: 80px;
  right: 0;
}
.left-backdrop {
  background: white;
  height: 100%;
  width: 140px;
  position: absolute;
  top: 119px;
}
.bottom-backdrop {
  height: 100px;
  background: white;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -35px;
}*/
.awssld__content {
  background: none !important;
}
